import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { login } from "./dbhelper";
import Signup from "./Signup";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showSignup, setShowSignup] = useState(false);

  const onSubmit = async (data) => {
    try {
      // Call the login function from dbhelper.js
      const userId = await login(data.username, data.password);

      // Store the userId in localStorage
      localStorage.setItem("userId", userId);

      // Show a success browser alert
      // alert('Login successful!');

      // Reload the page to render the Home component
      window.location.reload();
    } catch (error) {
      console.error("Login error:", error);
      alert("Login failed. Please try again.");
    }
  };

  const handleSignupClick = () => {
    setShowSignup(true);
  };

  return (
    <div
      className="hero bg-base-200"
      style={{ width: "100vw", height: "100vh" }}
    >
      <div
        className="hero-content flex-col"
        style={{ width: "100%", height: "100%" }}
      >
        {showSignup ? (
          <Signup />
        ) : (
          <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Username</span>
                  </label>
                  <input
                    type="text"
                    className={`input input-bordered ${
                      errors.username ? "input-error" : ""
                    }`}
                    {...register("username", { required: true })}
                  />
                  {errors.username && (
                    <span className="text-error">Username is required</span>
                  )}
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Password</span>
                  </label>
                  <input
                    type="password"
                    className={`input input-bordered ${
                      errors.password ? "input-error" : ""
                    }`}
                    {...register("password", { required: true })}
                  />
                  {errors.password && (
                    <span className="text-error">Password is required</span>
                  )}
                </div>
                <div className="spacer"></div>
                <div className="form-control mt-6">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </div>
              </form>

              <div className="text-center mt-4" style={{ textAlign: "right" }}>
                Don't have an Account ?
                <button className="btn btn-link" onClick={handleSignupClick}>
                  Sign up
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
