const { createClient } = require('@supabase/supabase-js');


const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

const supabase = createClient(supabaseUrl, supabaseKey);


// Check if username and password are valid and save the userid as session
async function login(username, password) {
  const { data, error } = await supabase
    .from('pcd_profile')
    .select('userid')
    .eq('username', username)
    .eq('password', password)
    .single();

  if (error) {
    throw error;
  }

  if (data) {
    // Save the userid as session
    // ...
    return data.userid;
  } else {
    throw new Error('Invalid username or password');
  }
}

// get all data 
async function fetchPublicProfile(userId) {
  try {
    const { data, error } = await supabase
      .from('pcd_profile')
      .select('name, img, description, pcd_links(url, social)')
      .eq('userid', userId)
      .single();

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    throw error;
  }
}

// Insert a new user into the pcd_profile table
async function createUser(name, email, username, password) {
  const { data, error } = await supabase
    .from('pcd_profile')
    .insert([{ name, email, username, password }])
    .select('userid, name, email, username');

  if (error) {
    throw error;
  }

  return data[0];
}

// Update the profile in the pcd_profile table
async function updateProfile(name, img, description, userid) {
  const { data, error } = await supabase
    .from('pcd_profile')
    .update({ name, img, description })
    .eq('userid', userid)
    .select('name, img, description')
    .single();

  if (error) {
    throw error;
  }

  return data;
}
// Insert links into the pcd_links table
async function insertLinks(userid, links) {
  const values = links.map(({ userid, url, social }) => ({ userid, url, social }));

  const { data, error } = await supabase
    .from('pcd_links')
    .insert(values);

  if (error) {
    throw error;
  }

  return data;
}


// Delete a link from the pcd_links table
async function deleteLink(userid, url, social) {
  const { data, error } = await supabase
    .from('pcd_links')
    .delete()
    .eq('userid', userid)
    .eq('url', url)

  if (error) {
    throw error;
  }

  return data;
}

async function fetchPages(userId) {
  try {
    const { data, error } = await supabase
      .from('pcd_profile')
      .select('userid, name, img, description, pcd_links(url, social)') // Include the pcd_links table as well
      .eq('userid', userId) // Filter by userId
      .not('description', 'is', null) // Filter out rows where description is null
      .single(); // Get a single row since we're filtering by userId

    if (error) {
      throw error;
    }

    return data; // Return the fetched page data
  } catch (error) {
    console.error('Error fetching pages:', error);
    throw error;
  }
}

module.exports = {
  fetchPages,
  login,
  createUser,
  updateProfile,
  insertLinks,
  deleteLink,
  fetchPublicProfile
};