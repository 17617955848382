import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchPublicProfile } from "./dbhelper";
import {
  FaGlobe,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaYoutube,
  FaTwitch,
  FaDiscord,
  FaTelegram,
  FaExternalLinkAlt,
} from "react-icons/fa";
import "./App.css"; // Importing the CSS file

const iconMapping = {
  website: <FaGlobe />,
  twitter: <FaTwitter />,
  facebook: <FaFacebook />,
  instagram: <FaInstagram />,
  linkedin: <FaLinkedin />,
  github: <FaGithub />,
  youtube: <FaYoutube />,
  twitch: <FaTwitch />,
  discord: <FaDiscord />,
  telegram: <FaTelegram />,
  default: <FaExternalLinkAlt />,
};

const PublicView = () => {
  const { userId } = useParams();
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const data = await fetchPublicProfile(userId);
        setProfile(data);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    getProfile();
  }, [userId]);

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="public_container mx-auto px-4 py-8">
      <div className="public_center-container">
        <div className="public_centered-card">
          <row>

        
          {/* <figure className="pt-4"> */}
            <img
              src={profile.img}
              alt={profile.name}
              className="public_circular-image"
            />
          {/* </figure> */}
          <div className="public_card-body">
            <h2 className="public_card-title">{profile.name}</h2>
            <p>{profile.description}</p>
            <div className="public_links mt-4">
              {profile.pcd_links &&
                profile.pcd_links.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="public_square-button"
                  >
                    {iconMapping[link.social.toLowerCase()] ||
                      iconMapping.default}
                  </a>
                ))}
            </div>
          </div>
          </row>
        </div>
      </div>
    </div>
  );
};

export default PublicView;
