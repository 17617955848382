import React from 'react';
import { useForm } from 'react-hook-form';
import { createUser } from './dbhelper';
const Signup = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (data) => {
    try {
      const userId = await createUser(data.name, data.email, data.username, data.password);
      console.log("userid",userId['userid']);
      window.location.reload();
    } catch (error) {
      console.error('Sign up error:', error);
      alert('Sign up failed. Please try again.');
    }
  };
  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col">
        <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Username</span>
                </label>
                <input
                  type="text"
                  className={`input input-bordered ${errors.username ? 'input-error' : ''}`}
                  {...register('username', { required: true })}
                />
                {errors.username && <span className="text-error">Username is required</span>}
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Name</span>
                </label>
                <input
                  type="text"
                  className={`input input-bordered ${errors.name ? 'input-error' : ''}`}
                  {...register('name', { required: true })}
                />
                {errors.name && <span className="text-error">Name is required</span>}
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="email"
                  className={`input input-bordered ${errors.email ? 'input-error' : ''}`}
                  {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                />
                {errors.email && <span className="text-error">Invalid email address</span>}
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Password</span>
                </label>
                <input
                  type="password"
                  className={`input input-bordered ${errors.password ? 'input-error' : ''}`}
                  {...register('password', { required: true, minLength: 6 })}
                />
                {errors.password && <span className="text-error">Password must be at least 6 characters</span>}
              </div>
              <div className='spacer'></div>
              <div className="form-control mt-6">
                <button type="submit" className="btn btn-primary">
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Signup;