import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './Login';
import Home from './Home';
import PublicView from './PublicView'; // Importing PublicView component
import 'daisyui/dist/full.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importing necessary components for routing

const root = ReactDOM.createRoot(document.getElementById('root'));

// Check if userId exists in localStorage
const userId = localStorage.getItem('userId');

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={userId ? <Home /> : <Login />} />
        <Route path="/public/:userId" element={<PublicView />} />
        </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
