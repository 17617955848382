import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { 
  FaGlobe, 
  FaTwitter, 
  FaFacebook, 
  FaInstagram,
  FaLinkedin, 
  FaGithub,
  FaYoutube,
  FaTwitch,
  FaDiscord,
  FaTelegram,
  FaPlus 
} from 'react-icons/fa';
import { updateProfile, insertLinks, deleteLink } from "./dbhelper"; // Import the required functions

const PageModal = ({ isOpen, onClose, editMode, page }) => {
  const { register, handleSubmit, setValue, watch } = useForm();
  const [links, setLinks] = useState([]);
  const [showLinkForm, setShowLinkForm] = useState(false);
  const [selectedSocial, setSelectedSocial] = useState("");
  const [newLink, setNewLink] = useState("");

  const socialOptions = [
    { value: "website", icon: <FaGlobe />, label: "Website" },
    { value: "twitter", icon: <FaTwitter />, label: "Twitter" },
    { value: "facebook", icon: <FaFacebook />, label: "Facebook" },
    { value: "instagram", icon: <FaInstagram />, label: "Instagram" },
    { value: "linkedin", icon: <FaLinkedin />, label: "LinkedIn" },
    { value: "github", icon: <FaGithub />, label: "GitHub" },
    { value: "youtube", icon: <FaYoutube />, label: "YouTube" },
    { value: "twitch", icon: <FaTwitch />, label: "Twitch" },
    { value: "discord", icon: <FaDiscord />, label: "Discord" },
    { value: "telegram", icon: <FaTelegram />, label: "Telegram" },
  ];

  const truncateUrl = (url, maxLength) => {
    if (url.length <= maxLength) {
      return url;
    }
    const truncatedUrl = `${url.slice(0, maxLength)}...`;
    return truncatedUrl;
  };

  useEffect(() => {
    if (editMode && page) {
      setValue("name", page.name);
      setValue("img", page.img);
      setValue("description", page.description);
      setLinks(page.pcd_links || []); // Set links from the page data
    }
  }, [editMode, page, setValue]);

  const handleAddLink = () => {
    if (newLink && selectedSocial) {
      setLinks([...links, { url: newLink, social: selectedSocial }]);
      setShowLinkForm(false);
      setSelectedSocial("");
      setNewLink("");
    }
  };

  const handleModalSubmit = async (data) => {
    try {
      const { name, img, description } = data;
      const userId = localStorage.getItem("userId");

// Update the profile
await updateProfile(
  name,
  img,
  description,
  userId
);
console.log("Profile updated successfully!");

      // Insert or update links
      if (links.length > 0) {
        const formattedLinks = links.map((link) => ({
          userid: userId,
          url: link.url,
          social: link.social,
        }));

        // Delete existing links for the user
        const existingLinks = page.pcd_links || [];
        for (const link of existingLinks) {
          await deleteLink(userId, link.url, link.social);
        }

        // Insert new links
        await insertLinks(userId, formattedLinks);
        console.log("Links updated successfully!");
      }

      // Reload the page after successful update
      window.location.reload();
    } catch (error) {
      console.error("Error updating page:", error);
      alert("Error updating page: " + error.message);
    }
  };

  const formData = watch();

  return (
    <div className={`modal ${isOpen ? "modal-open" : ""}`}>
      <div className="modal-box relative">
        <button
          className="btn btn-sm btn-circle absolute top-2 right-2"
          onClick={onClose}
        >
          ✕
        </button>
        <center>
          <h2 className="Modal_title">
            {editMode ? "Edit Page" : "Create New Page"}
          </h2>
        </center>
        <form onSubmit={handleSubmit(handleModalSubmit)} className="py-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Page Name</span>
            </label>
            <input
              type="text"
              placeholder="Enter page name"
              className="input input-bordered"
              {...register("name", { required: true })}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Photo URL</span>
            </label>
            <input
              type="text"
              placeholder="Enter photo URL"
              className="input input-bordered"
              {...register("img")}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Description</span>
            </label>
            <textarea
              placeholder="Enter description"
              className="textarea textarea-bordered"
              {...register("description")}
            />
          </div>
          {editMode && (
            <div className="form-control">
              <label className="label">
                <span className="label-text">Links</span>
              </label>
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Social</th>
                      <th>Link</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {links.map((link, index) => (
                      <tr key={index}>
                        <td>
                          {
                            socialOptions.find(
                              (option) => option.value === link.social
                            )?.label
                          }
                        </td>
                        <td className="text-green-500">
                          <div
                            className="tooltip tooltip-bottom"
                            data-tip={link.url}
                          >
                            {truncateUrl(link.url, 20)}
                          </div>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-error btn-sm"
                            onClick={() =>
                              setLinks(links.filter((_, i) => i !== index))
                            }
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button
                type="button"
                className="btn btn-primary btn-sm mt-2"
                onClick={() => setShowLinkForm(true)}
              >
                <FaPlus className="mr-2" />
                Add Link
              </button>
            </div>
          )}
          {showLinkForm && (
            <div className="form-control mt-4">
              <label className="label">
                <span className="label-text">New Link</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Enter link URL"
                  className="input input-bordered w-full"
                  value={newLink}
                  onChange={(e) => setNewLink(e.target.value)}
                />
                <select
                  className="select select-bordered"
                  value={selectedSocial}
                  onChange={(e) => setSelectedSocial(e.target.value)}
                >
                  <option value="">Select Social</option>
                  {socialOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddLink}
                >
                  Add
                </button>
              </div>
            </div>
          )}
          <div className="modal-action">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                console.log({ ...formData, links });
              }}
            >
              {editMode ? "Save Changes" : "Create Page"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PageModal;
