import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPlus, FaEdit } from "react-icons/fa";
import PageModal from "./PageModal";
import LinkModal from "./LinkModal";
import { fetchPages, updateProfile, insertLinks } from "./dbhelper";
import { MdContentCopy } from "react-icons/md";

const Home = () => {
  const { register, handleSubmit, reset } = useForm();
  const [pages, setPages] = useState([]);
  const [showPageModal, setShowPageModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (userId) {
          const fetchedPages = await fetchPages(userId);
          if (fetchedPages) {
            setPages(
              Array.isArray(fetchedPages) ? fetchedPages : [fetchedPages]
            );
          }
        }
      } catch (error) {
        console.error("Error fetching pages:", error);
      }
    };

    fetchData();
    // console.log("pages", pages);
  }, []);

  const handleCreatePage = async (data) => {
    try {
      const userId = localStorage.getItem("userId");
     
      if (!userId) {
        alert("User ID not found in local storage");
        return;
      }

      const { pageName: name, photo: img, description, links } = data;

      // Update the profile
    await updateProfile(
        name,
        img,
        description,
        userId
      );
      alert("Profile updated successfully!");

      // Insert links
      if (links && links.length > 0) {
        const formattedLinks = links.map((link) => ({
          userid: userId,
          url: link.link,
          social: link.social,
        }));
        await insertLinks(userId, formattedLinks);
        alert("Links inserted successfully!");
      }

      // Reset the form
      setShowPageModal(false);
      reset();
    } catch (error) {
      console.error("Error updating page:", error);
      alert("Error updating page: " + error.message);
    }
  };

  const handleEditPage = (page) => {
    setSelectedPage(page);
    setEditMode(true);
    setShowPageModal(true);
  };

  const handleCopyButton = () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const url = `https://profile-card-95f.pages.dev/public/${userId}`;
      navigator.clipboard.writeText(url).then(() => {
        console.log(`Copied URL to clipboard: ${url}`);
        alert(`Copied URL to clipboard: ${url}`);
      }).catch((error) => {
        console.error(`Error copying URL to clipboard: ${error}`);
      });
    } else {
      console.error("User ID not found in session storage");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userId");
    window.location.reload();
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <nav className="custom-nav">
        <p className="title text-3xl font-bold">Profile Card</p>
        <button className="btn btn-primary" onClick={handleLogout}>
          Logout
        </button>
      </nav>
      <div className="center-container">
        {pages.length === 0 ? (
          <div className="center-button">
            <button
              className="btn btn-primary btn-circle"
              onClick={() => {
                setEditMode(false);
                setShowPageModal(true);
              }}
            >
              <FaPlus />
            </button>
          </div>
        ) : (
          pages &&
          pages.map((page) => (
            <div
              key={page.id}
              className="centered-card card bg-base-200 shadow-xl"
            >
              <figure className="pt-4">
                <img
                  src={page.img}
                  alt={page.name}
                  className="circular-image"
                />
              </figure>
              <div className="card-body">
                <h2 className="card-title">{page.name}</h2>
                <p>{page.description}</p>

                <div
                  className="links mt-4"
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(80px, 1fr))",
                    gap: "10px",
                  }}
                >
                  {page.pcd_links &&
                    page.pcd_links.map((link, index) => (
                      <a
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block text-blue-500"
                      >
                        {link.social}
                      </a>
                    ))}
                </div>

                <div className="card-actions">
                <button
  className="btn btn-primary btn-circle"
  onClick={handleCopyButton}
>
  <MdContentCopy />
</button>
                  <button
                    className="btn btn-primary btn-circle"
                    onClick={() => handleEditPage(page)}
                  >
                    <FaEdit />
                  </button>
                 
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <PageModal
        isOpen={showPageModal}
        onClose={() => setShowPageModal(false)}
        onSubmit={handleSubmit(handleCreatePage)}
        register={register}
        editMode={editMode}
        page={selectedPage}
      />
      <LinkModal
        isOpen={showLinkModal}
        onClose={() => setShowLinkModal(false)}
        register={register}
      />
    </div>
  );
};

export default Home;
