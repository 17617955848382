import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaLink, FaGlobe, FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';

const LinkModal = ({ isOpen, onClose, register }) => {
  const { handleSubmit, reset } = useForm();
  const [selectedIcon, setSelectedIcon] = useState('');

  const iconOptions = [
    { value: 'globe', icon: <FaGlobe /> },
    { value: 'twitter', icon: <FaTwitter /> },
    { value: 'facebook', icon: <FaFacebook /> },
    { value: 'instagram', icon: <FaInstagram /> },
  ];

  const handleModalSubmit = (data) => {
    console.log(data);
    reset();
  };

  return (
    <div className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className="modal-box relative">
        <button
          className="btn btn-sm btn-circle absolute right-2 top-2"
          onClick={onClose}
        >
          ✕
        </button>
        <h3 className="text-lg font-bold">Add Link</h3>
        <form onSubmit={handleSubmit(handleModalSubmit)} className="py-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Link</span>
            </label>
            <div className="input-group">
              <span>
                <FaLink />
              </span>
              <input
                type="text"
                placeholder="Enter link URL"
                className="input input-bordered w-full"
                {...register('link', { required: true })}
              />
            </div>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Icon</span>
            </label>
            <div className="btn-group">
              {iconOptions.map((option) => (
                <button
                  key={option.value}
                  className={`btn ${selectedIcon === option.value ? 'btn-active' : ''}`}
                  onClick={() => setSelectedIcon(option.value)}
                >
                  {option.icon}
                </button>
              ))}
            </div>
            <input
              type="hidden"
              value={selectedIcon}
              {...register('icon')}
            />
          </div>
          <div className="modal-action">
            <button type="submit" className="btn btn-primary">
              Add Link
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LinkModal;